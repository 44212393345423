import actions from './actions';

const initState = { idToken: null, tipoUsuario: null };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      // console.log('ENTREI NO LOGIN SUCCESS', action);
      return {
        ...state,
        idToken: action.payload.token,
        tipoUsuario: 'adm',
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
